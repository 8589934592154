import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
  {
    image: "./images/service/galery-image-01.jpg",
    title: "Frontend | Maquetación",
    description: "Sitios adaptables a todos los dispositivos",
    tecnologias: "HTML / React / Astro / Wordpress / Tailwind",
  },
  {
    image: "./images/service/galery-image-02.jpg",
    title: "Backend | Base de Datos",
    description: "Base de datos y API para sitios",
    tecnologias: "PHP / MYSQL",
  },
  {
    image: "./images/service/galery-image-03.jpg",
    title: "Web App y Dashboards",
    description: "Programación a medida para aplicaciones web",
    tecnologias: "Paneles / Gráficos / Estadísticas / Analytics",
  },
];
const ServiceFive = ({ textAlign, serviceStyle }) => {
  return (
    <div className="row row--15 service-wrapper">
      {ServiceList.map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="inner">
                <div className="content">
                  <h4
                    className="title"
                    dangerouslySetInnerHTML={{ __html: val.title }}
                  ></h4>
                  <p
                    className="description"
                    dangerouslySetInnerHTML={{ __html: val.description }}
                  ></p>
                </div>
                <div className="image">
                  <img src={`${val.image}`} alt="card Images" />
                </div>
                <div className="content">                  
                  <em
                    className="description"
                    dangerouslySetInnerHTML={{ __html: val.tecnologias }}
                  ></em>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceFive;
