import React from "react";
import Typed from "react-typed";
import SEO from "../common/SEO";
import { FiArrowRight } from "react-icons/fi";
import HeaderTwo from "../common/header/HeaderTwo";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import AboutOne from "../elements/about/AboutOne";
import ServiceFive from "../elements/service/ServiceFive";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";
import ContactOne from "../elements/contact/ContactOne";

const BusinessConsulting2 = () => {
  return (
    <>
      <SEO title="Sistemas Web" />
      <main className="page-wrapper">
        <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent height-850">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <span className="subtitle">PROGRAMADORES WEB</span>
                  <h1 className="title theme-gradient display-two fontH1">
                    Desarrollamos <br />{" "}
                    <Typed
                      strings={[
                        "Sitios Coorporativos",
                        "Landing Pages",
                        "Wordpress",
                        "Sistemas de Pedidos",
                        "Base de Datos",
                      ]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  <p className="description">
                    Grupo de Freelancers dedicados al Desarrollo Web
                  </p>
                  <div className="button-group">
                    <a
                      className="btn-default btn-medium round btn-icon"
                      target="_blank"
                      rel="noreferrer"
                      href="https://api.whatsapp.com/send?phone=5491136879576"
                    >
                      Whatsapp{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </a>
                    <a
                      className="btn-default btn-medium btn-border round btn-icon"
                      href="#contacto"
                    >
                      Contactanos{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />

        {/* Start About Area  */}
        <AboutOne />
        {/* End About Area  */}

        <Separator />
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap" id="servicios">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Qué podemos desarrollar"
                  title="Servicios y tecnologías que ofrecemos"
                  description="Contamos con una amplia variedad de recursos para tus necesidades.<br /> Usamos las últimas tecnologías en desarrollo web."
                />
              </div>
            </div>
            <ServiceFive serviceStyle="gallery-style" textAlign="text-start" />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Call To Action Area  */}
        <div className="rwt-callto-action-area">
          <div className="wrapper">
            <CalltoActionFive />
          </div>
        </div>
        {/* End Call To Action Area  */}

        <Separator />
        {/* Start Mission Area   */}
        <Mission />
        {/* Start Mission Area  */}

        <Separator />
        {/* Start Testimonial Area  */}
        <div className="rwt-testimonial-area rn-section-gap" id="beneficios">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Nuestra opinión"
                  title="Beneficios de tener un sitio web"
                  description="Cuáles son los beneficios más importantes de tener tu propio sitio o aplicación web"
                />
              </div>
            </div>
            <TestimonialThree teamStyle="" />
          </div>
        </div>
        {/* End Testimonial Area  */}

        <Separator />
        {/* Start Contact Area  */}
        <div className="rwt-contact-area rn-section-gap" id="contacto">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb--40">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Contacto"
                  title="Comunicate con nosotros"
                  description=""
                />
              </div>
            </div>
            <ContactOne />
          </div>
        </div>
        {/* End Contact Area  */}

        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};
export default BusinessConsulting2;
