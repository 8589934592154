import { useState, useRef } from "react";
import { FiMenu } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import Nav from "./Nav";
import MobileMenu from "./MobileMenu";
import useStickyHeader from "./useStickyHeader";

const HeaderTwo = ({ btnStyle, HeaderSTyle }) => {
  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };

  const ref = useRef();
  const sticky = useStickyHeader(50);
  const check = true;
  const headerClasses = `header-default ${sticky && check ? "sticky" : ""}`;

  return (
    <>
      <header id="home"
        ref={ref}
        className={`rn-header header-default header-left-align ${HeaderSTyle} ${headerClasses}`}
      >
        <div className="container position-relative py-2 fondoNav">
          <div className="row align-items-center">
            <div className="col-lg-10 col-md-8 col-6 position-static">
              <div className="header-left d-flex">
                <Logo
                  image="/images/logo/logo.png"
                  image2="/images/logo/logo-dark.png"
                />
                <nav className="mainmenu-nav d-none d-lg-block d-lg-flex align-items-center">
                  <Nav />
                </nav>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <div className="header-right">
                <div className="header-btn">
                  <a
                    className={`btn-default ${btnStyle}`}
                    target="_blank"
                    rel="noreferrer"
                    href="https://api.whatsapp.com/send?phone=5491136879576"
                  >
                    Whatsapp
                  </a>
                </div>
                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div className="hamberger">
                    <span
                      className="hamberger-button"
                      onClick={onCanvasHandler}
                    >
                      <FiMenu />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
    </>
  );
};
export default HeaderTwo;
