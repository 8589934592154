import React from 'react';
import Typed from 'react-typed';
import { BsTelegram } from "react-icons/bs";

const AboutOne = () => {
    return (
        <div className="rwt-about-area rn-section-gap" id="desarrollo">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/about-1.jpg" alt="Sobre nosotros" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content px-3">
                            <div className="section-title">
                                <h2 className="title fontAuto mb-2">Pasos en el desarrollo <br /> {" "}
                                    <Typed className="theme-gradient fontAuto"
                                        strings={[
                                            "1- Detallanos tu necesidad",
                                            "2- Te ofreceremos soluciones",
                                            "3- Eliges la más indicada",
                                            "4- Programamos el sitio",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>                                
                                <ol>
                                    <li>Envianos tu consulta, necesidad o problema a resolver.</li>
                                    <li>Analizaremos todas las soluciones posibles usando diversas tecnologías de desarrollo web. Calcularemos bien cuál lenguaje de programación sería el más óptimo para la tarea.</li>
                                    <li>Te enviaremos las opciones posibles con los diferentes costos y tiempos de desarrollo para que puedas elegir el más conveniente.</li>
                                    <li>Una vez seleccionada la opción más indicada, se comenzará con el proceso de diseñar y/o programar el sitio o sistema web.</li>
                                </ol>
                                <div className="read-more-btn mt--40">
                                    <a className="btn-default" target="_blank" href="https://t.me/sistemasweb" rel="noreferrer" ><BsTelegram size={30} className="mb-1" /><span className="ms-2">Consultanos por Telegram</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOne
