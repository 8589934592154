import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    ¿Quiénes somos?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>Somos un grupo de jóvenes freelancers y que nos especializamos en el diseño, maquetación y programación de sitios y sistemas web. </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    ¿Cómo trabajamos y qué podemos desarrollar?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>Nuestra tarea es escuchar las necesidades del cliente y ofrecer las mejores opciones para resolverlas. Pueden crear diferentes tipos de páginas web, como una Landing Page en HTML usando Astro JS o un Dashboard en React Js. Además, podemos diseñar y construir una base de datos en MYSQL. O también ayudar a agencias de marketing o usuarios que administran su propio sitio a configurar los servicios de Google, como el Tag Manager o Analytics, y personalizar todos los eventos que necesiten registrar para sus campañas.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    ¿Hacemos diseños personalizados de páginas o sistemas web?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>Nuestro equipo está formado por expertos en diseño web, programación y desarrollo, y trabajamos juntos para ofrecer soluciones únicas y personalizadas para cada cliente. Con años de experiencia en el campo, hemos trabajado con una amplia variedad de proyectos web y estamos siempre comprometidos a proporcionar resultados excepcionales. </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    ¿Cómo puede contactarnos?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>Puede enviarnos cualquier duda o consulta desde nuestro formulario de contacto, Whatsapp o también Telegram</Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}
export default AccordionOne

