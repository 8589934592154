import React from 'react';
import Typed from 'react-typed';
import AccordionOne from '../accordion/AccordionOne';

const mission = () => {
    return (
        <div className="rn-company-mission-are rn-section-gap" id="faqs">
            <div className="container">
                <div className="row row--30">
                    <div className="col-lg-6">
                        <div className="mission-title px-2 px-md-0">
                            <h2 className="title">Nuestro trabajo <br /> {" "}
                                <Typed className="theme-gradient"
                                    strings={[
                                        "Escuchar",
                                        "Analizar",
                                        "Diseñar",
                                        "Resolver",
                                        "Programar",
                                    ]}
                                    typeSpeed={80}
                                    backSpeed={5}
                                    backDelay={1000}
                                    loop
                                />
                            </h2>
                            <p>Nuestro trabajo consiste en darle a tu proyecto o idea el mejor desarrollo para implementarse dentro de internet. Para llevarlo a cabo, te ofreceremos plantillas con las últimas tecnologías para renovar tu sitio o también diseñar uno desde cero.</p>

                            <p>Cada sitio o sistema se pensará para posicionarlo en este mundo tan competitivo. Usando estrategias de SEO en el código para que los buscadores lo interpreten de la forma más óptima. Además, te ayudaremos a configurar todo lo relacionado a Google Tag Manager o Analytics para tu agencia de marketing o para que veas los datos vos mismo.</p>                            
                        </div>
                    </div>
                    <div className="col-lg-6 mt_md--30 mt_sm--30">
                        <AccordionOne customStyle="" />
                    </div>
                </div>
            </div>                        
        </div>
    )
}
export default mission;
