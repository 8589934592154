import React from "react";

const Nav = () => {
  return (
    <ul className="mainmenu">
      <li>
        <a href="#home">Home</a>
      </li>
      <li>
        <a href="#desarrollo">Desarrollo</a>
      </li>
      <li>
        <a href="#servicios">Servicios</a>
      </li>
      <li>
        <a href="#faqs">FAQs</a>
      </li>
      <li>
        <a href="#beneficios">Beneficios</a>
      </li>
      <li>
        <a href="#contacto">Contacto</a>
      </li>
    </ul>
  );
};
export default Nav;
