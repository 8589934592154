import PropTypes from "prop-types";
import React from 'react'


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>{title} - Maquetación y Desarrollo de Sitios Web</title>            
            <meta name="description" content="Sistemas Web - Una empresa joven dedicada al desarrollo de sistemas y sitios web. Desarrollamos sistemas para todo tipo de dispositivos y necesidades." />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;


