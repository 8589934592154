import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight } from "react-icons/fi";

const callToActionData = {
  title: "¿Necesita un hosting para su sitio web?",
  subtitle:
    "Si está necesitando un hosting personalizado para su sistema o sitio web, no dude en consultarnos. Contamos con planes personalizados",
  btnText: "Consultar Hosting",
};

const CalltoActionSeven = () => {
  return (
    <div className="rn-callto-action clltoaction-style-default style-7">
      <div className="container">
        <div className="row row--0 align-items-center content-wrapper">
          <div className="col-lg-8 col-md-8">
            <div className="inner">
              <div className="content text-start">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <p className="subtitle">{callToActionData.subtitle}</p>
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <div className="call-to-btn text-start mt_sm--20 text-md-end">
                <a className="btn-default btn-icon" href="#contacto">
                  {callToActionData.btnText}{" "}
                  <i className="icon">
                    <FiArrowRight />
                  </i>
                </a>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CalltoActionSeven;
