import React, { useState } from "react";
import Axios from "axios";

const Result = () => {
  return (
    <span className="success-message bg-white p-2">
      Su mensaje fue enviado y en breve lo contactaremos
    </span>
  );
};
const Alerta = (nombreError, emailError, mensajeError) => {
  return (
    <div className="success-message">
      <p>
        {nombreError} <br /> {emailError} <br /> {mensajeError}{" "}
      </p>
    </div>
  );
};

function ContactForm({ props, formStyle }) {
  const [result, showresult] = useState(false);
  const [alerta, setAlerta] = useState(false);
  const [nombre, setNombre] = useState("");
  const [nombreError, setNombreError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [mensajeError, setMensajeError] = useState("");
  const [telefono, setTelefono] = useState("");
  const urlHostApi =
    window.location.host === "localhost:3005"
      ? "http://localhost/git/sistemas_web/api/"
      : "https://sistemasweb.ar/api/";

  const sendEmail = (e) => {
    e.preventDefault();

    if (email === "") {
      setAlerta(true);
      emailError("Ingrese un email");
    } else {
      setEmailError("");
    }
    if (nombre === "") {
      setAlerta(true);
      setNombreError("Ingrese un nombre");
    } else {
      setNombreError("");
    }
    if (mensaje === "") {
      setAlerta(true);
      setMensajeError("Ingrese su mensaje");
    } else {
      setMensajeError("");
    }
    if (email !== "" && nombre !== "" && mensaje !== "") {
      setEmailError("");
      setNombreError("");
      setMensajeError("");

      Axios.post(urlHostApi + "contacto.php", {
        email: email,
        nombre_apellido: nombre,
        comentario: mensaje,
        telefono: telefono,
      })
        .then((response) => {
          //console.table(response.data);
          //console.log(response);
          showresult(true);
        })
        .catch(function (errordata) {
          //console.table(errordata);
        });
    }
    e.target.reset();
    showresult(true);
  };

  return (
    <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
      <div className="form-group">
        <input
          type="text"
          name="nombre_apellido"
          placeholder="Su nombre"
          required
          onChange={(e) => {
            setNombre(e.target.value);
            setAlerta("");
          }}
        />
      </div>

      <div className="form-group">
        <input
          type="email"
          name="email"
          placeholder="Dirección de Email"
          required
          onChange={(e) => {
            setEmail(e.target.value);
            setAlerta("");
          }}
        />
      </div>

      <div className="form-group">
        <input
          type="text"
          name="telefono"
          placeholder="Número de Teléfono"
          onChange={(e) => {
            setTelefono(e.target.value);
            setAlerta("");
          }}
        />
      </div>

      <div className="form-group">
        <textarea
          name="comentario"
          placeholder="Su Mensaje"
          required
          onChange={(e) => {
            setMensaje(e.target.value);
          }}
        ></textarea>
      </div>

      <div className="form-group">
        <button className="btn-default btn-large">Enviar Consulta</button>
      </div>

      <div className="form-group">{result ? <Result /> : null}</div>
      <div className="form-group">
        {alerta ? (
          <Alerta
            nombreError={nombreError}
            emailError={emailError}
            mensajeError={mensajeError}
          />
        ) : null}
      </div>
    </form>
  );
}
export default ContactForm;
